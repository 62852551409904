import React, { useEffect, Component, useState } from "react"
import {Container,Table, Dropdown, Row, Col, Button, Form} from 'react-bootstrap';

import { SignInAgreement_API } from "../../queries/api_links";

import { useContainerContext } from "../../store/ContainerContext";

const SellerFactFindModule = (props) => {
    const context = useContainerContext();
    const { sign_in_aggrement } = context.state;
    const { SignInAgreementDispatch } = context.actions;

    const [seller_fact_find, setSellerFactFind] = useState('');

    const [property_data, setPropertyData] = useState(props.property_data);

    const [Font_Cls, setFontCls] = useState('LatterSlant');
    const [change_font, setChangeFont] = useState(false)

    const [addPerson, setAddPerson] = useState(false);
    const [form_values, setFormState] = useState({});
    const [error_msg, setErrorMsg] = useState('');

    const myRef = React.createRef();

    const handleChange = (evt) => {
        const value = evt.target.value;
        setFormState({
            ...form_values,
            [evt.target.name]: value
        });
        setSellerFactFind({...seller_fact_find, [evt.target.name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var appointment_id = property_data.appointment && property_data.appointment.Id;
        var urls = property_data.urls;
        var property_id = property_data.id;
        var SellerFactFind = seller_fact_find;
        var post_data = {...sign_in_aggrement, Id:appointment_id, SellerFactFind:seller_fact_find};

        //console.log("post_data", post_data);

        var res = SignInAgreement_API(property_id, post_data).then(res => {
            console.log("res_seller_fact", res);
            if(res.status === 200){
                setErrorMsg("success");
            } else{
                setErrorMsg("error");
            }
            
        });
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    const removePerson = () => {
        setAddPerson(false);
        delete form_values.first_name_2
        delete form_values.initials_2
    }

    useEffect(()=>{
        setSellerFactFind(props.seller_fact_find);
    }, [props.seller_fact_find]); 
    
    useEffect(()=>{
        SignInAgreementDispatch(property_data.sign_in_agreement)
    }, [property_data]); 

  return (
    <>
        <div ref={myRef} />
        {
            error_msg && (
                error_msg === "success" ?
                <div className={`alert alert-success`}>Success! Details are updated.</div>
                :
                <div className={`alert alert-danger`}>Error! Could not updated.</div>
            )
        }
        
        <div className="card-block">
            <h3 className="mt-0">Ownership</h3>
            <Form action="javascript:;" id="seller-fact-form" className="seller-fact-form" method="post" onSubmit={handleSubmit}>

            <ul className="mt-3">
                <li>
                    <strong>Are you the legal owner of the property?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="OwnershipQuestion1" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion1 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="OwnershipQuestion1" onChange={handleChange} value="No" checked={seller_fact_find && seller_fact_find.OwnershipQuestion1 === "No" ? true : false} /> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Other
                        <input type="radio" name="OwnershipQuestion1" onChange={handleChange} value="Other" checked={seller_fact_find && seller_fact_find.OwnershipQuestion1 === "Other" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Who else besides you, has the legal ownership or legal claim?</strong><br />
                    <input type="text" className="text_input" name="OwnershipQuestion2" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnershipQuestion2} />
                </li>
                <li>
                    <strong>If you are not the legal owner, please provide their details and state your <br/>relationship to the owner and the reason for seeking a valuation.</strong><br />
                    <input type="text" className="text_input" name="OwnershipQuestion3" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnershipQuestion3} />
                </li>
                <li>
                    <strong>We believe you purchased the property on 12/03/19, is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="OwnershipQuestion4" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion4 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="OwnershipQuestion4" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion4 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don't Know
                        <input type="radio" name="OwnershipQuestion4" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion4 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>We believe the tenure of this property to be Freehold, is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="OwnershipQuestion5" value="Yes" onChange={handleChange} onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion5 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="OwnershipQuestion5" value="No" onChange={handleChange} onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion5 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="OwnershipQuestion5" value="Don't Know" onChange={handleChange} onChange={handleChange} checked={seller_fact_find && seller_fact_find.OwnershipQuestion5 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>If leasehold, please provide:</strong><br />
                    <Row className={`lease_hold_cls`}>
                        <label className="col-lg-4"> Length of Lease<br />
                            <div className="text_input">
                                <input type="text" name="OwnershipQuestion6" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnershipQuestion6} />
                                <span className="yrs">Years</span>
                            </div>
                        </label>
                        <label className="col-lg-4"> Service Charge<br />
                            <div className="text_input">
                                <span className="dollar">£</span>
                                <input type="text" name="OwnershipQuestion7" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnershipQuestion7} />
                            </div>
                        </label>
                        <label className="col-lg-4"> Ground Rent<br />
                            <div className="text_input">
                                <span className="dollar">£</span>
                                <input type="text" name="OwnershipQuestion8" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnershipQuestion8} />
                            </div>
                        </label>
                    </Row>
                </li>
            </ul>
            <br />

            <h3>Legal and Planning</h3>
            <ul className="mt-3">
                <li>
                    <strong>We believe your property is a listed building, is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion1" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion1 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion1" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion1 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion1" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion1 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>We believe your property is in a conservation area, is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion2" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion2 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion2" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion2 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion2" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion2 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are there any restrictive convenants that affect the property?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion3" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion3 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion3" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion3 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know 
                        <input type="radio" name="LegalPlanningQuestion3" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion3 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>We understand the following planning appliations were approved for works carried out;</strong><br /><br />

                    <strong>Planning application name 1 – is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion4" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion4 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion4" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion4 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                </li>
                <li>
                    <strong>Planning application name 2 – is this correct?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion5" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion5 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion5" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion5 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                </li>
                <li>
                    <strong>If applicable, did you seek Building Regulations approval for works carried out?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion6" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion6 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion6" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion6 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion6" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion6 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are there any guarantees, warranties or insurance which apply to this property?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion7" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion7 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion7" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion7 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion7" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion7 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are there any issues surrounding rights of way or rights of access to/from other properties?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion8" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion8 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion8" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion8 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion8" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion8 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are there any current or historic disputes with a neighbour, an individual, or any public body that affect the property?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="LegalPlanningQuestion9" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion9 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="LegalPlanningQuestion9" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion9 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="LegalPlanningQuestion9" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.LegalPlanningQuestion9 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
            </ul>
            <br />

            <h3>The Building and Grounds</h3>
            <ul className="mt-3">
                <li>
                    <strong>Has the property ever suffered from damage that has resulted in an insurance claim?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion1" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion1 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion1" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion1 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion1" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion1 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are you aware of any flooding at the property since you have owned it?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion2" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion2 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion2" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion2 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion2" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion2 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are there any known faults or issues affecting the fabric of the building?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion3" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion3 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion3" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion3 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion3" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion3 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Has the property been affected by any subsidence or other structural movement?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion4" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion4 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion4" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion4 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion4" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion4 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Are all mains services available?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion5" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion5 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion5" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion5 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion5" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion5 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Is the heating system and electrical installation in good working order?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion6" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion6 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion6" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion6 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion6" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion6 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
                <li>
                    <strong>Is there anything else that you think you should declare that may affect the likely sale value of the property?</strong><br />
                    <label className="radio_opt">Yes
                        <input type="radio" name="TheBuildingAndGroundsQuestion7" value="Yes" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion7 === "Yes" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">No
                        <input type="radio" name="TheBuildingAndGroundsQuestion7" value="No" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion7 === "No" ? true : false}/> 
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio_opt">Don’t know
                        <input type="radio" name="TheBuildingAndGroundsQuestion7" value="Don't Know" onChange={handleChange} checked={seller_fact_find && seller_fact_find.TheBuildingAndGroundsQuestion7 === "Don't Know" ? true : false}/>
                        <span className="checkmark"></span> 
                    </label>
                </li>
            </ul>
            <br />

            <h3>Owner’s Declaration</h3>
            <ul className="mt-3 owner_decl">
                <li>
                    <Row>
                        <label className="col-lg-6"><strong>Full Name:</strong><br />
                            <div className="text_input">
                                <input type="text" name="OwnersDeclarationFullName" placeholder="Michael Parkinson" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnersDeclarationFullName} />
                            </div>
                        </label>
                        <label className="col-lg-6"><strong>Initials</strong><br />
                            <div className="text_input">
                                <input type="text" name="OwnersDeclarationInitials" placeholder="MP" onChange={handleChange} defaultValue={seller_fact_find && seller_fact_find.OwnersDeclarationInitials} />
                            </div>
                        </label>
                    </Row>

                    {
                        !addPerson && (
                            <Button variant="secondary add_sign" type="button" onClick={() => setAddPerson(true)}>add person</Button>
                        ) 
                    }
                    

                    <div className="sign_sec">
                        <span className="sml_fnt">Preview</span>
                        <div className="sign_box">
                            <div className="sign_name">
                                <span className="docTxt">DocuSigned by:</span>
                                <span className={`owner_sign ${Font_Cls}`}>{form_values && form_values.OwnersDeclarationFullName ? form_values.OwnersDeclarationFullName : "Michael"}</span>
                                <span className="docNo">023167A35E4556</span>
                            </div>

                            <div className="sign_name initial">
                                <span className="docTxt">Ds</span>
                                <span className={`owner_sign ${Font_Cls}`}>{form_values && form_values.OwnersDeclarationInitials ? form_values.OwnersDeclarationInitials : "MP"}</span>
                                <span className="docNo"></span>
                            </div>
                        </div>
                        
                        {/* <a href="javascript:;" className="change_sty" onClick={()=>setChangeFont(!change_font)}>Change Style</a>

                        {
                            change_font && (
                                <div className="signFont">
                                    <select name="change_font" onChange={(e)=>setFontCls(e.target.value)}>
                                        <option value="">Select Font</option>
                                        <option value="">Default</option>
                                        <option value="Misela">Misela</option>
                                        <option value="HandletterSignature">Handletter Signature</option>
                                        <option value="Ballano">Ballano</option>
                                        <option value="AmelliaInk">Amellia-Ink</option>
                                        <option value="Alpinet">Alpinet</option>
                                        <option value="Hammington">Hammington</option>
                                        <option value="NeutralAutumn">Neutral Autumn</option>
                                    </select>
                                </div>
                            )
                        }                                                         */}

                        <span className="byClick">By Clicking Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial.</span>
                    </div>
                </li>

                {
                    addPerson && (
                    <li>
                        <Row>
                            <label className="col-lg-6"><strong>Full Name:</strong><br />
                                <div className="text_input">
                                    <input type="text" name="OwnersDeclarationFullName2" placeholder="Michael Parkinson" onChange={handleChange} value={seller_fact_find && seller_fact_find.OwnersDeclarationFullName2} />
                                </div>
                            </label>
                            <label className="col-lg-6"><strong>Initials</strong><br />
                                <div className="text_input">
                                    <input type="text" name="OwnersDeclarationInitials2" placeholder="MP" onChange={handleChange} value={seller_fact_find && seller_fact_find.OwnersDeclarationInitials2} />
                                </div>
                            </label>
                        </Row>
                        <Button variant="secondary add_sign" type="button" onClick={removePerson}>Remove person</Button>
                        <div className="sign_sec">
                            <span className="sml_fnt">Preview</span>
                            <div className="sign_box">
                                <div className="sign_name">
                                    <span className="docTxt">DocuSigned by:</span>
                                    <span className={`owner_sign ${Font_Cls}`}>{form_values && form_values.OwnersDeclarationFullName2 ? form_values.OwnersDeclarationFullName2 : "Michael"}</span>
                                    <span className="docNo">023167A35E4556</span>
                                </div>

                                <div className="sign_name initial">
                                    <span className="docTxt">Ds</span>
                                    <span className={`owner_sign ${Font_Cls}`}>{form_values && form_values.OwnersDeclarationInitials2 ? form_values.OwnersDeclarationInitials2 : "MP"}</span>
                                    <span className="docNo"></span>
                                </div>
                            </div>

                            <span className="byClick">By Clicking Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial.</span>
                        </div>
                    </li>
                    )
                }        
                </ul>

            <Button variant="primary" className="adopt_btn" type="submit">adopt and sign</Button>

        </Form>

        </div>
                                                                        
    </>
  )
}

export default SellerFactFindModule