import React, {useState} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import SignImg from "../assests/images/sign_img.svg";

import SellerRightSide from "../components/modules/seller-right-side"
import PropertyRightSide from "../components/modules/property-right-side"
import SellerTab from "../components/modules/seller-tab"
import PropertyTab from "../components/modules/property-tab"
import Seller_Fact_Find from "../components/modules/seller_fact_find"

import { useContainerContext } from "../store/ContainerContext";

const SellerFactFind = (props) => {
  
  let path_name = (props.location.pathname).split('/');
  let get_seller_id = path_name[path_name.length-1];

  const context = useContainerContext();
  const { property_data } = context.state;

  var seller_fact_find = property_data && property_data.seller_fact_find;


  //console.log("seller_fact_find", seller_fact_find);

  return(

    <>
      <SEO title="Seller Fact Find" description="Seller Fact Find" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Seller" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">

                    {/* <SellerTab tab_name="Seller Fact Find" get_seller_id={get_seller_id} /> */}
                    <PropertyTab tab_name="Seller Fact Find" get_seller_id={get_seller_id} />
                    {/* Tab Content */}

                    <div className="sell_details sell_fact_find">
                        <Row>
                            <Col lg={12} md={12}>
                                <Row>
                                    <Col lg={8} md={8}>
                                        <Seller_Fact_Find seller_fact_find={seller_fact_find} property_data={property_data} />
                                    </Col>

                                    <PropertyRightSide get_seller_id={get_seller_id} />

                                </Row>
                            </Col>    
                        </Row>
                    </div>
              </div>

              
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default SellerFactFind